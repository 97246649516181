<template>
  <div class="school-data-student-attendance">
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click.prevent="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>

    <v-card
      v-if="!isLoadingTable"
      class="my-6"
    >
      <div class="pa-6">
        <v-row class="align-center justify-space-between">
          <v-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            cols="12"
          >
            <h2>Rekap Absensi</h2>
            <span>Halaman untuk rekap absensi siswa</span>
          </v-col>
          <v-col
            xl="5"
            lg="5"
            md="5"
            sm="6"
            cols="12"
            class="py-3"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              @change="searchHandler($event)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                label="Pilih Kelas"
                outlined
                :items="classes"
                item-text="name"
                item-value="uuid"
                dense
                @change="filterByClass($event)"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            xl="4"
            lg="4"
            md="4"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                v-model="start_date"
                :close-on-content-click="true"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="recap.start_date"
                    label="Tanggal Mulai"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @change="searchStartDate($event)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="recap.start_date"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @change="searchStartDate($event)"
                  @input="date = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            xl="4"
            lg="4"
            md="4"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                v-model="end_date"
                :close-on-content-click="true"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="recap.end_date"
                    label="Tanggal Berakhir"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @change="searchEndDate($event)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="recap.end_date"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @change="searchEndDate($event)"
                  @input="date = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        :page="page"
        :headers="headers"
        :items="attendances"
        :loading="isLoadingData"
        :server-items-length="totalItems"
        :total-pages.sync="totalPages"
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:item.button>
          <v-btn
            color="primary"
            dense
          >
            Cetak
          </v-btn>
        </template>
      </v-data-table>
      <div class="d-flex justify-space-between pt-2 pb-2">
        <div class="ml-4 mt-3">
          <h5>Total item: {{ totalItems }}</h5>
        </div>
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="7"
        ></v-pagination>
      </div>
    </v-card>
    <v-skeleton-loader
      v-else
      class="mt-6"
      v-bind="attrs"
      type="table-thead,table-tbody,table-tfoot"
    >
    </v-skeleton-loader>
  </div>
</template>

<script>
import { mdiArrowLeft, mdiMagnify } from '@mdi/js'

export default {
  name: 'SchoolDataStudentAttendance',
  components: {},
  props: {},
  data() {
    return {
      page: 1,
      totalPages: 0,
      totalPageData: this.totalPages,
      isLoadingData: false,
      isLoadingTable: true,
      totalItems: 0,
      search: '',
      icons: {
        mdiMagnify,
        mdiArrowLeft,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Lengkap', value: 'student.full_name' },
        { text: 'Hadir (H)', value: 'attend' },
        { text: 'Alfa (A)', value: 'absence' },
        { text: 'Sakit (S)', value: 'sick' },
        { text: 'Izin (S)', value: 'permission' },
      ],
      start_date: null,
      end_date: null,
      recap: {
        class: [],
        start_date: '',
        end_date: '',
      },
      service: 'attendance',
      attendances: [],
      students: [],
      classes: [{ uuid: 0, name: 'Semua' }],
      class: '',
      school: [],
      school_uuid: '',
    }
  },
  watch: {
    class: {
      handler() {
        this.getAttendance()
      },
    },
    page: {
      handler() {
        this.getAttendance()
      },
    },
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.page = 1
          }, 300)
          this.getAttendance()
        }
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.getAttendance()
    this.getClass()
  },
  methods: {
    searchHandler(data) {
      this.search = data
    },
    async getAttendance(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        search: this.search,
        class_uuid: this.class,
        page: this.page,
      }).then(({ data }) => {
        this.attendances = data.data.map((attendance, index) => ({
          ...attendance,
          index: index + 1,
        }))
        this.isLoadingTable = false
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
    },
    async getClass() {
      await this.$services.ApiServices.list('class', { school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.classes.push(...data.data)
        },
        err => console.error(err),
      )
    },

    async searchStartDate(date) {
      this.recap.start_date = date
      await this.getAttendance({
        start_date: this.recap.start_date,
        end_date: this.recap.end_date === '' ? date : this.recap.end_date,
      })
    },
    async searchEndDate(date) {
      this.recap.end_date = date

      await this.getAttendance({
        start_date: this.recap.start_date === '' ? date : this.recap.start_date,
        end_date: this.recap.end_date,
      })
    },
    getSchoolUuid() {
      this.school_uuid = this.$route.params.uuid
    },
    filterByClass(uuid) {
      this.class = uuid
    },
    autofillStartDate() {
      this.recap.start_date = new Date().toISOString().substr(0, 10)
    },
    autofillEndDate() {
      this.recap.end_date = new Date().toISOString().substr(0, 10)
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
